'use client';
import { TrackProvider } from '@americastestkitchen/ui/app/TrackProvider';
import Footer from '@americastestkitchen/ui/footer/index';
import trackUI from 'components/layouts/ui-layout/trackUI';

export default function UIFooterV2() {
  return (
    <TrackProvider track={trackUI}>
      <Footer />
    </TrackProvider>
  );
}
